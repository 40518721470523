import {
    Box,
    Button,
    Typography
} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download';
import BuildIcon from '@mui/icons-material/Build';
import Socials from ".//Socials";
import Avatar from ".//Avatar";
import {Link} from 'react-router-dom';

export default function DrawerContent(width) {
    let sizingFactor;

    if (width > 767) {
        sizingFactor = 0.25;
    } else {
        sizingFactor = 0.7;
    }

    return <Box padding={4} minWidth={width * sizingFactor} height="100%" role='presentation' sx={{
        backgroundImage: `url(/drawerBackground.png)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }}>
        <Avatar size={200}/>
        <Typography variant="h4"
                    component={Link}
                    to='./'
                    padding={2}
                    fontFamily="Crimson Pro"
                    fontWeight="fontWeightBold"
                    color="#002e3f"
                    sx={{
                        backgroundColor: "primary",
                        backgroundImage: `linear-gradient(45deg, #002e3f, #2792BB)`,
                        backgroundSize: "100%",
                        backgroundRepeat: "repeat",
                        backgroundClip: "text",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent"
                    }}>Aaron Thompson</Typography>
        <Box paddingBottom={6}><Socials/></Box>
        <Button startIcon={<DownloadIcon/>} variant="contained" size='small' color='secondary'
                aria-label='resume-button'
                sx={{margin: "1em"}}
                component={Link} to='./myresume'>
            Resume
        </Button>
        <Button startIcon={<BuildIcon/>} variant="contained" size='small' color='secondary'
                aria-label='projectdata-button'
                sx={{margin: "1em"}}
                component={Link} to='./projects'>
            Projects
        </Button>
    </Box>
}