export function getProjects() {
    return [{
        "name": "Trail-Pack",
        "summary": "A React Native app for managing backpacking gear and calculating pack weight.",
        "stack": "React Native, Firebase, react-native-paper",
        "page": "./projects/trailpack",
        "repo": "https://github.com/asthomp/Trail-Pack"
    },
        {
            "name": "Wine Data Lake",
            "summary": "Web app for browsing a database of wine industry techsheets.",
            "stack": "React.js, App Engine, PostgreSQL",
            "page": "./projects/wine",
            "demo": "https://wine-data-lake-376821.uw.r.appspot.com"
        },

        {
            "name": "Messier94", "summary": "A science-fiction themed text-only adventure game with simple mechanics.",
            "stack": "C++, Command Line Gaming",
            "page": "./projects/messier94", "repo": "https://github.com/asthomp/Messier94"
        },
        {
            "name": "Combinations Quiz!",
            "summary": "A short quiz game that asks the user to solve combinations problems. Combinations problems are generated randomly and solved recursively.",
            "page": "./projects/combinations",
            "repo": "https://github.com/asthomp/CombinationsQuiz"
        },
        {
            "name": "Spellbound Archive", "summary": "A web app for managing a user's card collection.",
            "stack": "App Engine, Node.js, React.js, no-SQL", "page": "./projects/spellbound"
        },

        {
            "name": "Raducate", "summary": "A mobile app and positioning guide for radiologic technologists.",
            "stack": "Flutter, Firebase"
        }, {
            "name": "Stoplight Bot", "summary": "A Discord bot that helps with community moderation.",
            "stack": "Discord.py, Discord API, Python3",
            "page": "./projects/stoplight", "repo": "https://github.com/asthomp/Stoplight-Bot"
        },

    ];
}
