import {useEffect, useRef, useState} from 'react';
import {Outlet} from "react-router-dom";
import {Drawer, ThemeProvider} from '@mui/material'
import AppTheme from "./AppTheme";
import './styles/styles.css';
import NavTop from "./views/NavTop";
import DrawerContent from "./views/DrawerContent";

export default function App() {
    const theme = AppTheme();
    const contentRef = useRef();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [width, setWidth] = useState(250);

    // Retrieves width of the app window.
    const getWidth = () => {
        if (contentRef.current && contentRef.current.clientWidth > 0) {
            setWidth(contentRef.current.clientWidth);
        }
    }


    // Calculates width upon render
    useEffect(() => {
        getWidth();
    }, [contentRef]);


    // Updates 'width' of the window when resized
    useEffect(() => {
        window.addEventListener("resize", getWidth);
    }, []);


    return (<ThemeProvider theme={theme}>
            <div className="appBody" ref={contentRef}>
                <NavTop setIsDrawerOpen={setIsDrawerOpen} width={width}/>
                <Drawer anchor='right' open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
                    <DrawerContent width={width}/>
                </Drawer>
                <div className="appBodyContent">
                    {/* Desktop & Landscape Padding */}
                    {width && width >= 800 && <div className="sidePadding"></div>}
                    <div className="contentOutlet">
                        <Outlet/></div>
                    {/* Desktop & Landscape Padding */}
                    {width && width >= 800 && <div className="sidePadding"></div>}
                </div>
            </div>
        </ThemeProvider>
    );
}