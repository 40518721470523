import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import App from "./App";
import Projects from "./views/Projects";
import Landing from "./views/Landing";
import MyResume from "./views/MyResume";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App/>}>
                    <Route index element={<Landing/>}/>
                    <Route path="myresume" element={<MyResume/>}/>
                    <Route path="projects" element={<Projects/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);