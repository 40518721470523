import {IconButton} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHackerrank} from '@fortawesome/free-brands-svg-icons'


export default function Socials() {

    const socials = [{
        "title": "GitHub",
        "link": "https://github.com/asthomp",
        "icon": <GitHubIcon fontSize="inherit"/>,
        "alt": "Navigate to Aaron Thompson's Github profile."
    },
        {
            "title": "LinkedIn",
            "link": "https://www.linkedin.com/in/asthomp/",
            "icon": <LinkedInIcon fontSize="inherit"/>,
            "alt": "Navigate to Aaron Thompson's LinkedIn profile."
        },
        {
            "title": "HackerRank",
            "link": "https://www.hackerrank.com/profile/thompsaa",
            "icon": <FontAwesomeIcon icon={faHackerrank}/>,
            "alt": "Navigate to Aaron Thompson's HackerRank profile."
        },
        {
            "title": "Email",
            "link": "mailto:aaron@aaronthompson.dev",
            "icon": <EmailIcon fontSize="inherit"/>,
            "alt": "E-mail Aaron Thompson!"
        }]

    return <div>
        {socials.map((x) => {
            return <IconButton color="primary" size="large" aria-label={x.alt} href={x.link}>
                {x.icon}
            </IconButton>
        })}
    </div>
}