import {
    Button,
    Typography,
    Divider,
    IconButton,
    CardContent,
    Card,
    CardActions
} from '@mui/material'
import {useNavigate} from "react-router-dom";
import GitHubIcon from '@mui/icons-material/GitHub';
import {getProjects} from "../data/getProjects";

export default function Projects() {
    const navigate = useNavigate();
    const p = getProjects();

    return <>
        <Typography gutterBottom variant="h5" component="div" sx={{m: 2}}>
            I worked on...
        </Typography>
        <Divider flexItem variant="middle" sx={{m: 2}}/>

        {p.map((x) => {
            return <Card sx={{marginBottom: "1em"}}>
                <CardContent>
                    <Typography variant="h5">
                        {x.name}
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                        {x.stack}
                    </Typography>
                    <Typography variant="body1" color="text.primary" sx={{paddingTop: "2em"}}>
                        {x.summary}
                    </Typography>
                </CardContent>
                <CardActions>
                    {x && x.page && <Button size="small" onClick={() => navigate(x.page)}
                                            alt={`Learn more about the ${x.name} project.`}>Learn More</Button>}
                    {x && x.demo && <Button size="small" onClick={() => window.location = x.demo}
                                            alt={`Check out a demo of the ${x.name} project.`}>Demo</Button>}
                    {x && x.repo && <IconButton variant="contained" color="primary"
                                                aria-label={`Navigate to ${x.name}'s Github repo.`}
                                                onClick={() => window.location = x.repo}>
                        <GitHubIcon/>
                    </IconButton>}
                </CardActions>
            </Card>
        })}
    </>
}