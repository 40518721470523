import {List, ListItem, ListItemButton, ListItemText, Typography} from "@mui/material";
import '../styles/MyResume.css';
import {Link} from "react-router-dom";

export default function MyResume() {
    return <div className="resume">
        <div className="resumeHeader">
            <div className="resumeTitle">
                <Typography variant="h4" sx={{
                    fontFamily: 'Oswald',
                    fontWeight: 900,
                }}>Aaron Thompson</Typography>
                <Typography>Software Developer</Typography>
            </div>
            <div className="resumeContacts">
                <Typography>3617B Courtland PL S</Typography>
                <Typography>Seattle, WA 98114</Typography>
                <Typography>(206) 660-9098</Typography>
                <Typography><Link to={"mailto:aaron@aaronthompson.dev"}>aaron@aaronthompson.dev</Link></Typography>
                <Typography><Link to={"http://www.github.com/asthomp"}>www.github.com/asthomp</Link></Typography>
                <Typography><Link to={"http://www.linkedin.com/in/asthomp"}>www.linkedin.com/in/asthomp</Link></Typography>
            </div>
        </div>

        <div className="resumeSection">
            <div className="resumeSectionHeader">
                <Typography variant="h5" sx={{
                    fontFamily: 'Oswald',
                    fontWeight: 900,
                }}>
                    PROFILE</Typography>
            </div>
            <div className="resumeSectionBody">
                <Typography>
                    Recent computer science graduate with a 10+ year background in the healthcare industry.
                    Collaborative problem-solver with a track record for optimizing workflows across teams. Seeking
                    a challenging position to leverage programming skills and build impactful software.
                </Typography></div>
        </div>

        <div className="resumeSection">
            <div className="resumeSectionHeader">
                <Typography variant="h5" sx={{
                    fontFamily: 'Oswald',
                    fontWeight: 900,
                }}>
                    SKILLS</Typography>
            </div>
            <div className="resumeSectionGrid">
                <div className="resumeSectionGridItem">
                    <Typography>◾ JavaScript</Typography>
                    <Typography>◾ SQL & No-SQL Databases</Typography>
                    <Typography>◾ Node.js</Typography>
                    <Typography>◾ C & C++</Typography></div>

                <div className="resumeSectionGridItem">
                    <Typography>◾ React</Typography>
                    <Typography>◾ React Native</Typography>
                    <Typography>◾ Python</Typography>
                    <Typography>◾ GitHub & Git</Typography>
                </div>
            </div>
        </div>

        <div className="resumeSection">
            <div className="resumeSectionHeader">
                <Typography variant="h5" sx={{
                    fontFamily: 'Oswald',
                    fontWeight: 900,
                }}>
                    FAVORITE PROJECTS</Typography>
            </div>
            <div className="resumeSectionBody">
                <List>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemText primary="Trail-Pack"
                                          secondary="Deployed with Firebase, this React Native project was built using Expo. It features the Firestore no-SQL database."
                                          component={Link}
                                          to="https://github.com/asthomp/Trail-Pack"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemText primary="Wine-Data-Lake"
                                          secondary="A capstone project deployed with App Engine, this project has a React desktop-only frontend and a Flask (Python) backend API. It uses a PostgreSQL database."
                                          component={Link}
                                          to="https://github.com/asthomp/Trail-Pack"/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </div>
        </div>
        <div className="resumeSection">
            <div className="resumeSectionHeader">
                <Typography variant="h5" sx={{
                    fontFamily: 'Oswald',
                    fontWeight: 900,
                }}>
                    WORK HISTORY</Typography>
            </div>
            <div className="resumeSectionBody">
                <div className="workHistorySection">
                    <Typography variant="subtitle1"><b>University of Washington,
                        Harborview</b> — <i>Radiographer</i></Typography>
                    <Typography variant="subtitle2"><b>June 2010 - PRESENT</b></Typography>
                    <ul>
                        <li><Typography>During shortages, implemented protocols that halved resource
                            consumption</Typography>
                        </li>
                        <li><Typography>Given an increased demand for services, redesigned workflows to double
                            caseloads by
                            eliminating duplicate work and increasing cross-departmental collaboration</Typography>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="resumeSection">
            <div className="resumeSectionHeader">
                <Typography variant="h6" sx={{
                    fontFamily: 'Oswald',
                    fontWeight: 900,
                }}>
                    EDUCATION</Typography>
            </div>

            <div className="resumeSectionBody">
                <div className="resumeEducationItem">
                    <Typography variant="subtitle1"><b>Oregon State University</b> — <i>Bachelor of Computer
                        Science</i></Typography>
                    <Typography variant="subtitle2"><b>2019 - 2022, Summa Cum Laude</b></Typography>
                </div>
                <div className="resumeEducationItem">
                    <Typography variant="subtitle1"><b>Bellevue College</b> — <i>Bachelor of Applied Science,
                        Radiation & Imaging Sciences</i></Typography>
                    <Typography variant="subtitle2"><b>2015, Magna Cum Laude</b></Typography>
                </div>
            </div>
        </div>
    </div>
}