import {Card, CardContent, Typography} from "@mui/material";
import '../styles/Landing.css';

export default function Landing() {

    return <Card className="landingCard"> <CardContent><Typography
        variant="h2"
        align="center"
        fontFamily="Crimson Pro"
        fontWeight="fontWeightBold"
        color="#002e3f"
        sx={{
            backgroundColor: "primary",
            backgroundImage: `linear-gradient(45deg, #002e3f, #2792BB)`,
            backgroundSize: "100%",
            backgroundRepeat: "repeat",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent"
        }}>Hi, I'm Aaron.</Typography></CardContent></Card>
}