import {useState} from 'react';
import {AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Stack, Button, Divider} from '@mui/material';
import MenuIcon from "@mui/icons-material/Menu";
import DownloadIcon from "@mui/icons-material/Download";
import {Home, KeyboardArrowDown} from "@mui/icons-material";
import {Link} from 'react-router-dom';


export default function NavTop({setIsDrawerOpen, width}) {
    const [anchorProjects, setAnchorProjects] = useState(null);

    return <><AppBar position='fixed'>
        <Toolbar>
            {/* Logo & Title */}
            <div style={{flexGrow: 1}}>
                <Button startIcon={<Home/>} size='large' color='inherit' aria-label='A button that goes back home.'
                        component={Link} to='/'/>
            </div>

            <div className="navBarLinks">
                <Stack direction='row' spacing={3}>
                    {/* Desktop-View Menu & Toolbar */}
                    {width && width >= 700 && <>
                        <Button startIcon={<DownloadIcon/>} size='large' color='inherit' aria-label='A button that links to my resume.'
                                component={Link} to="./myresume">
                            Resume
                        </Button>
                        <Button color='inherit'
                                id='cards-button'
                                onClick={(event) => setAnchorProjects(event.currentTarget)}
                                aria-controls={Boolean(anchorProjects) ? 'cards-menu' : undefined}
                                aria-haspopup='true'
                                aria-expanded={Boolean(anchorProjects) ? 'true' : undefined}
                                endIcon={<KeyboardArrowDown/>}
                        >Projects</Button></>
                    }
                    {/* Desktop & Mobile Drawer Menu*/}
                    <IconButton size='large' edge='start' color='inherit' aria-label='logo'
                                onClick={() => setIsDrawerOpen(true)}><MenuIcon/></IconButton>
                </Stack>
            </div>

            {/* Drop-Down Menu Items (Desktop-View) */}
            <Menu id='projects-menu' anchorEl={anchorProjects} open={Boolean(anchorProjects)}
                  MenuListProps={{'aria-labelledby': 'projects-button'}} onClose={() => setAnchorProjects(null)}
                  transformOrigin={{vertical: 'top', horizontal: 'right'}}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                <MenuItem onClick={() => setAnchorProjects(null)}>
                    <Typography component={Link}
                                to='./projects'
                                sx={{
                                    textDecoration: 'none',
                                    color: 'text.primary',
                                    fontFamily: 'inherit',
                                    fontWeight: 'inherit',
                                }}>View All Projects</Typography>
                </MenuItem>
                <Divider flexItem variant="middle" sx={{m: 2}}/>
                <MenuItem onClick={() => setAnchorProjects(null)}>
                    <Typography component="a"
                                to='http://wine-data-lake-376821.uw.r.appspot.com'
                                sx={{
                                    textDecoration: 'none',
                                    color: 'text.primary',
                                    fontFamily: 'inherit',
                                    fontWeight: 'inherit',
                                }}>Wine Data Lake</Typography>
                </MenuItem>
            </Menu>
        </Toolbar>
    </AppBar>
        {/* Spacer for the fixed toolbar. */}
        <Toolbar/>
    </>
}