import {createTheme} from '@mui/material'

export default function AppTheme() {
    return createTheme({
        palette: {
            mode: "light",
            primary: {
                main: '#002e3f',
                light: '#e4f8f5',
            },
            secondary: {
                main: '#095179'
            }

        },
        typography: {
            fontFamily: [
                'Montserrat',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(',')
        }
    })
}